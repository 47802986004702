<template>
  <div>
    <div @click="visible = true">
      <slot />
    </div>

    <a-modal title="选择合同" :visible="visible" @cancel="cancel" :footer="null" width="600px">
      <a-input-search style="margin-bottom: 16px" placeholder="请输入合同名称/设计编号搜索" enter-button
        @search="onSearch"></a-input-search>

      <a-table bordered :data-source="list" :loading="loading" @change="onChange" :rowClassName="() => 'clickable'"
        :pagination="{
                          total,
                          current,
                          pageSize,
                          showTotal: (total) => `共 ${total} 条记录`,
                        }" :customRow="
                                  (record) => {
                                    return {
                                      on: {
                                        click: () => {
                                          onTableRowClick(record);
                                        },
                                      },
                                    };
                                  }
                                ">
        <a-table-column title="设计编号" data-index="code" width="120px">
        </a-table-column>

        <a-table-column title="合同名称" data-index="name"> </a-table-column>

        <a-table-column title="合同金额(万元)" data-index="contractAmount" align="right" width="100px">
          <template slot-scope="contractAmount">
            <Money :money="contractAmount" />
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import request from "@/api/request";

function fetchList(data) {
  return request({
    url: "/project-service/contract/selector",
    method: "post",
    data,
  });
}

export default {
  data() {
    return {
      visible: false,
      searchValue: "",
      current: 1,
      pageSize: 10,
      loading: false,
      list: [],
      total: 0,
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    },
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        keyWord: this.searchValue,
      })
        .then((res) => {
          if (res && Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    onSearch(value) {
      this.searchValue = value;
      this.current = 1;
      this.getList();
    },

    onTableRowClick(record) {
      this.$emit("change", record);
      this.visible = false;
    },

    cancel() {
      this.$emit("cancel");
      this.visible = false;
    },
  },
};
</script>