<template>
  <div>
    <Pane />
    <a-form
      :form="form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 12 }"
      :colon="false"
      @submit="handleSubmit"
    >
      <div class="container">
        <div class="header">
          <img src="../../../assets/contract-register/icon-info.png" alt />
          <span>基本信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="申请部门">
              <a-button block style="text-align: left">
                {{
                department.deptUniqueName
                }}
              </a-button>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="申请人">
              <a-button block style="text-align: left">
                {{
                user.userName
                }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">合同名称</span>
              <MyContractSelector @change="onSelectContract">
                <a-button
                  class="ellipsis"
                  style="margin-top: 4px; text-align: left"
                  block
                >{{ selectedContract.name }}</a-button>
              </MyContractSelector>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="设计编号">
              <a-button block style="text-align: left">
                <span v-if="selectedContract.code">{{ selectedContract.code }}</span>
                <span>&nbsp;</span>
              </a-button>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="原合同金额(万元)">
              <a-button block style="text-align: left">
                <span v-if="selectedContract.contractAmount">
                  <Money :money="selectedContract.contractAmount" />
                </span>
                <span>&nbsp;</span>
              </a-button>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="变更后合同金额(万元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                v-decorator="[
                                'amountNew',
                                { rules: [{ required: true, message: '请输入！' }] },
                              ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../../assets/contract-register/icon-project.png" alt />
          <span>子项目列表</span>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="子项目列表">
          <div style="margin-top: 4px">
            <a-table
              bordered
              :dataSource="selectedContract.projects ? selectedContract.projects : []
                          "
              :pagination="false"
            >
              <a-table-column title="设计编号" width="140px">
                <template slot-scope="text">{{ text.designCode }}</template>
              </a-table-column>
              <a-table-column title="子项目名称">
                <template slot-scope="text">{{ text.name }}</template>
              </a-table-column>
              <a-table-column title="原子项目金额(万元)" align="right" width="150px">
                <template slot-scope="text">
                  <Money :money="text.projectAmount" />
                </template>
              </a-table-column>
              <a-table-column title="变更后子项目金额(万元)" align="right" width="150px">
                <template slot-scope="text">
                  <a-input-number
                    :formatter="$inputNumberFormat"
                    size="small"
                    v-model="text.amountNew"
                  />
                </template>
              </a-table-column>
            </a-table>
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="container">
        <div class="header">
          <img src="../../../assets/contract-register/icon-file.png" alt />
          <span>内部资料</span>

          <div class="extra">
            <FileUpload @uploaded="uploadedInternal">
              <a-button type="primary" size="small">上传</a-button>
            </FileUpload>
          </div>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="内部资料">
          <div style="margin-top: 4px">
            <a-table
              bordered
              :dataSource="internalFileList
                          "
              :pagination="false"
            >
              <a-table-column title="文件名">
                <template slot-scope="text">
                  <a :href="text.completePath" target="_blank">{{ text.name }}</a>
                </template>
              </a-table-column>

              <a-table-column title="操作" align="center">
                <template slot-scope="text, row, index">
                  <a href="#" class="danger" @click="removeInternal(index)">删除</a>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>
      <div class="container">
        <div class="header">
          <img src="../../../assets/contract-register/icon-file.png" alt />
          <span>外部资料</span>

          <div class="extra">
            <FileUpload @uploaded="uploadedExternal">
              <a-button type="primary" size="small">上传</a-button>
            </FileUpload>
          </div>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" label="外部资料">
          <div style="margin-top: 4px">
            <a-table
              bordered
              :dataSource="externalFileList
                          "
              :pagination="false"
            >
              <a-table-column title="文件名">
                <template slot-scope="text">
                  <a :href="text.completePath" target="_blank">{{ text.name }}</a>
                </template>
              </a-table-column>

              <a-table-column title="操作" align="center">
                <template slot-scope="text, row, index">
                  <a href="#" class="danger" @click="removeExternal(index)">删除</a>
                </template>
              </a-table-column>
            </a-table>
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="center">
        <a-space>
          <a-button
            type="primary"
            htmlType="submit"
            :loading="saveLoading"
          >{{ id ? "重新提交审批" : "提交审批" }}</a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-form>

    <CommentModal :visible="commentVisible" @setVisible="setCommentVisible" @done="done" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MyContractSelector from "@/components/my-contract-selector";
import CommentModal from "@/components/comment-modal";
import { fetchTaskDetail } from "@/api/approval";
import request from "@/api/request";
import FileUpload from "@/components/file-upload";

function fetchDetail(id) {
  return request({
    url: "/project-service/contract/getContractWithActPrj/" + id
  });
}

function add(data) {
  return request({
    url: "/project-service/contract/updateAmount",
    method: "post",
    data
  });
}

export default {
  components: {
    MyContractSelector,
    CommentModal,
    FileUpload
  },

  data() {
    return {
      form: this.$form.createForm(this),

      selectedContract: {},

      saveLoading: false,
      id: null, // 重新提交时的 ID
      detail: {}, // 重新提交时获取到的数据

      commentVisible: false, // 审批意见弹窗
      storageValue: {}, // 暂存的数据

      internalFileList: [],
      externalFileList: [],
      isFinancialApproval: undefined
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"])
  },

  mounted() {
    const { taskId, instanceId, id } = this.$route.query;
    console.log("id", id, taskId, instanceId);

    if (id && this.id !== id) {
      this.id = id;

      fetchTaskDetail({ taskId, instanceId }).then(res => {
        console.log("detail", res);

        if (
          res &&
          res.currentTask &&
          typeof res.currentTask.extra === "string"
        ) {
          const obj = JSON.parse(res.currentTask.extra);
          const {
            contractId,
            code,
            name,
            amountOld,
            amountNew,
            projectList,
            internalFileList,
            externalFileList,
            isFinancialApproval
          } = obj.contractChangeAmountData ?? {};
          this.form.setFieldsValue({
            amountNew: parseFloat((amountNew / 10000).toFixed(6))
          });
          this.selectedContract = {
            id: contractId,
            code,
            name,
            contractAmount: amountOld,
            projects: Array.isArray(projectList)
              ? projectList.map(item => {
                  return {
                    ...item,
                    projectAmount: item.amountOld,
                    amountNew: parseFloat((item.amountNew / 10000).toFixed(6))
                  };
                })
              : []
          };

          this.internalFileList = internalFileList;
          this.externalFileList = externalFileList;
          this.isFinancialApproval = isFinancialApproval;
        }
      });
    }
  },

  methods: {
    onSelectContract(obj) {
      console.log("obj", obj);
      fetchDetail(obj.id).then(res => {
        console.log("res", res);
        this.selectedContract = res;
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          if (!this.selectedContract.id) {
            this.$message.error("请选择合同！");
            return;
          }
          if (Array.isArray(this.selectedContract.projects)) {
            let flag = false;
            let num = 0;
            this.selectedContract.projects.forEach(item => {
              if (typeof item.amountNew !== "number") {
                flag = true;
              } else {
                num += item.amountNew * 10000;
              }
            });
            if (flag) {
              this.$message.error("请填写子项目变更金额！");
              return;
            }

            if (Math.abs(values.amountNew * 10000 - num) > 0.000001) {
              this.$message.error("子项目变更金额之和不等于合同变更金额！");
              return;
            }
          }

          if (!this.internalFileList.length) {
            this.$message.error("请上传内部资料");
            return;
          }

          if (!this.externalFileList.length) {
            this.$message.error("请上传外部资料");
            return;
          }

          const params = {
            id: this.id ? this.id : undefined, // 重新提交

            applyUserId: this.user.uuid,
            applyUserName: this.user.userName,
            applyDeptId: this.department.deptId,
            applyDeptName: this.department.deptUniqueName,

            contractId: this.selectedContract.id,
            amountOld: this.selectedContract.contractAmount,
            amountNew: values.amountNew * 10000,
            projectList: this.selectedContract.projects.map(item => {
              return {
                designCode: item.designCode,
                id: item.id,
                name: item.name,
                amountOld: item.projectAmount,
                amountNew: item.amountNew
                  ? item.amountNew * 10000
                  : item.amountNew
              };
            }),

            internalFileList: this.internalFileList,
            externalFileList: this.externalFileList,
            isFinancialApproval: this.isFinancialApproval
          };

          this.storageValue = params;
          this.commentVisible = true;
          this.saveLoading = true;
        }
      });
    },

    // 审批意见弹窗设置
    setCommentVisible(visible) {
      this.commentVisible = visible;
      this.saveLoading = false;
    },

    done(comment) {
      this.saveLoading = true;
      add({
        ...this.storageValue,
        comment
      })
        .then(res => {
          console.log("res", res);
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.commentVisible = false;
          this.saveLoading = false;
        });
    },

    // 上传附件
    uploadedInternal(list) {
      this.internalFileList = [...this.internalFileList, ...list];
    },
    uploadedExternal(list) {
      this.externalFileList = [...this.externalFileList, ...list];
    },
    removeInternal(index) {
      this.internalFileList.splice(index, 1);
    },
    removeExternal(index) {
      this.externalFileList.splice(index, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
    color: #1890ff;
    font-weight: bold;
    font-size: 14px;

    img {
      width: 14px;
      margin-right: 4px;
    }

    .extra {
      margin-left: auto;
    }
  }

  .tip {
    color: #999;
  }
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>
