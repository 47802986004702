var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"click":function($event){_vm.visible = true}}},[_vm._t("default")],2),_c('a-modal',{attrs:{"title":"选择合同","visible":_vm.visible,"footer":null,"width":"600px"},on:{"cancel":_vm.cancel}},[_c('a-input-search',{staticStyle:{"margin-bottom":"16px"},attrs:{"placeholder":"请输入合同名称/设计编号搜索","enter-button":""},on:{"search":_vm.onSearch}}),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"rowClassName":() => 'clickable',"pagination":{
                        total: _vm.total,
                        current: _vm.current,
                        pageSize: _vm.pageSize,
                        showTotal: (total) => `共 ${total} 条记录`,
                      },"customRow":(record) => {
                                  return {
                                    on: {
                                      click: () => {
                                        _vm.onTableRowClick(record);
                                      },
                                    },
                                  };
                                }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"设计编号","data-index":"code","width":"120px"}}),_c('a-table-column',{attrs:{"title":"合同名称","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"合同金额(万元)","data-index":"contractAmount","align":"right","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(contractAmount){return [_c('Money',{attrs:{"money":contractAmount}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }